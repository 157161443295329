.home {
  display: flex;
}

.productContainer {
  display: flex;
  width: 78%;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.filters {
  background-color: black;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
  height: 86vh;
  border-radius: 2%;
}

.filters > span {
  padding-bottom: 20px;
}

.carousel {
  display: flex;
  // margin: 0 10%;
  justify-content: center;
  background-color: whitesmoke;
}

.intro-section {
  display: flex;
  justify-content: center;
  text-align: center;
  background-image: url('../../assets/gray-wall-background.webp');
  height: 60vh;
  p {
    text-align: left;
  }
  div {
    width: 45%;
    margin: 2%;
  }
}
// .intro-section h2,
// p {
//   @media (max-width: 700px) {
//     display: block;
//   }
// }

.carousel-text {
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);

  @media (max-width: 700px) {
    display: block !important;
  }
}

.banner {
  display: flex;
  justify-content: center;
  border: 2px solid red;
  div {
    width: 45%;
  }
}

.banner img {
  object-fit: scale-down;
  width: 50%;
}
