// *,
// *:before,
// *:after {
//   box-sizing: inherit;
// }

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h5 {
  font-family: 'Inter', sans-serif;
}

.header {
  display: block;
  text-align: center;
  font-size: 30px;
  margin: 10px 0;
}

.cartitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-bottom: 20px;
}

.cartItemDetail {
  display: flex;
  flex: 1;
  padding: 0 20px;
  flex-direction: column;
}

/* Home Page */

.home {
  display: flex;
}

.filters {
  background-color: #000033;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 10px;
  height: 86vh;
}

.filters > span {
  padding-bottom: 20px;
}

.title {
  font-size: 30px;
}

.products {
  width: 30%;
  margin: 10px;
}

.summary {
  width: 30%;
}
.search {
  align-self: center;
  margin-right: 5%;
}

.dropdown-toggle {
  padding: 0 !important;
}

.rating-style {
  display: flex;
  flex-direction: row;
  color: gold;
}
/* Media Queries */
@media (max-width: 771px) {
  .filters {
    width: 40%;
    padding: 10px;
    margin: 5px;
  }

  .filters > span {
    font-size: 10px;
  }

  .title {
    font-size: 18px !important;
  }

  .search {
    display: none !important;
  }

  .products {
    width: 100%;
  }

  .ezshop {
    align-self: center;
    margin-right: 5%;
  }
}

.logout {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0 10% 0 5%;
  transition: all 0.2s ease-in-out;
}

.logout:hover {
  transform: scale(1.5);
}

.ezshop {
  align-self: center;
  margin-right: 25%;
}

.authForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5%;
}

.checkout-btn {
  display: flex;
  width: 90%;
  margin: 5% 0 25% 5%;
}

.success {
  display: flex;
  justify-content: center;
  margin: 5%;
  img {
    width: 60vw;
  }
}
